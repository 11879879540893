@import "styles/variables";


.select > div:first-of-type {
  min-height: 44px;
}

.btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  @media screen and (max-width: $breakpoint-lg) {
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 16px;
  }
}

.large {
  font-size: 64px;
  line-height: 64px;

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 48px;
    line-height: 48px;
  }

  @media screen and (max-width: $breakpoint-md) {
    font-size: 32px;
    line-height: 32px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    font-size: 26px;
    line-height: 28px;
  }
}

.normal {
  font-size: 34px;
  line-height: 40px;

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 18px;
    line-height: 24px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    font-size: 16px;
  }
}

.small {
  font-size: 22px;
  line-height: 32px;

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 16px;
    line-height: 24px;
  }
}
