@import "styles/variables";
@import "styles/mixins";


.label {
  z-index: 7;
  left: 12px;
  top: 10px;
  position: absolute;
  pointer-events: none;
  padding: 0 2px;
  background-color: $white;
  border-radius: 5px;
  color: $placeholder;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 90%;
  text-overflow: ellipsis;
  &.xlarge {
    top: 13px;
    font-size: 16px;

    @media screen and (max-width: $breakpoint-lg) {
      font-size: 14px;
    }
  }
  &.smallPlaceholder {
    font-size: 13px;
    & + .borderWrapper > .legend {
      font-size: 14px;
    }
    @media screen and (min-width: $min-breakpoint-lg) and (max-width: $breakpoint-xxl) {
    left: 1px;
    max-width: calc(100% - 2px);
    word-spacing: -2px;
    text-overflow: unset; 

    & + .borderWrapper {
      padding: 0 2px;
    
      & > .legend {
        font-size: 13px;
      }
    }   
  }
}

  @include transition-default(all, 200ms);
  &.withValue,
  &.active {
    top: -9px;
    font-size: 13px;
    line-height: 16px;
    color: $lightBlack;
    z-index: 7;
    background-color: transparent;
  }
  &.active {
    color: $teal;
  }
  &.error {
    color: $pink;
  }

  @media screen and (max-width: $breakpoint-lg) {
    top: 8px;
    font-size: 12px;
  }
}

.baseLabel {
  color: $lightBlack;
  font-size: 16px;
  line-height: 20px;
}

.borderWrapper {
  display: block;
  top: -5px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  border: 1px solid $gray-border;
  border-radius: inherit;
  pointer-events: none;
  font-size: 0;
  &.active:not(.error) {
    border-color: $teal;
  }
  &.error {
    border-color: $pink;
  }
}

.legend {
  z-index: 7;
  width: auto;
  height: 11px;
  display: block;
  padding: 0;
  font-size: 15px;
  max-width: 0.01px;
  text-align: left;
  visibility: hidden;

  @include transition-default(max-width, 50ms, cubic-bezier(0, 0, 0.2, 1));
  &.withValue,
  &.active {
    max-width: 1000px;
  }
}

.fieldWrapper {
  position: relative;
  font-size: 0;
  &:not(.disabled):hover .borderWrapper:not(.error) {
    border-color: $teal;
  }
}

.largeError {
  font-size: 13px;
}

.customError {
  margin-bottom: 3px;
  padding-top: 5px;
  display: flex;
  align-items: flex-start;
}

.errorCircle {
  margin-right: 4px;
  position: relative;
  top: 1px;
}
