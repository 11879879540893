@import "styles/variables";

:global {
  .uppercase {
    text-transform: uppercase;
  }
  .bold {
    font-weight: 700;
  }
  .italic {
    font-style: italic;
  }
}

.content {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: $black;
  white-space: pre-line;
  display: block;
  &:link,
  &:visited {
    &:hover {
      color: $teal;
    }
  }

  @media screen and (max-width: $breakpoint-lg), print {
    font-size: 16px;
    line-height: 20px;
  }
}

.content-title {
  font-size: 13px;
  line-height: 20px;
  font-weight: bold;
  color: $black;
}

.small {
  font-size: 13px;
  line-height: 20px;
  color: $black;
  margin: 0;

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 12px;
    line-height: 15px;
  }

  @media print {
    font-size: 10px;
    line-height: 15px;
  }
}

.gray {
  font-size: 16px;
  line-height: 32px;
  margin: 0;
  
  @media screen and (max-width: $breakpoint-sm) {
    font-size: 16px;
    line-height: 20px;   
  }
}

.small-gray {
  font-size: 13px;
  line-height: 20px;
  color: $black;
  margin: 0;

  @media screen and (max-width: $breakpoint-xl) {
    font-size: 12px;
    line-height: 17px;
  }

  @media print {
    font-size: 10px;
    line-height: 15px;
  }
}

.small-gray-cart {
  font-size: 13px;
  line-height: 17px;
  color: $black;
  margin: 0;
  text-transform: initial;
  span {
    font-weight: 700;
    color: $black;
    text-transform: uppercase;
  }

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 14px;
    line-height: 15px;
  }

  @media print {
    font-size: 1em;
    line-height: 1.6em;
  }
}

.h1 {
  font-size: 64px;
  line-height: 64px;
  font-weight: bold;
  margin-bottom: 24px;

  @media screen and (max-width: $breakpoint-lg), print {
    font-size: 48px;
    line-height: 48px;
    margin-bottom: 18px;
  }

  @media screen and (max-width: $breakpoint-md) {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 12px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    font-size: 26px;
    line-height: 28px;
  }
}

.h2 {
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.02em;
  margin-bottom: 24px;

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 12px;
  }

  @media print {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 5px;
  }
}

.h3 {
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  color: $black;
  letter-spacing: 0.02em;

  @media screen and (max-width: $breakpoint-lg), print {
    font-size: 20px;
    line-height: 22px;
  }
}

.h3-title {
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  color: $black;
  letter-spacing: 0.02em;

  @media screen and (max-width: $breakpoint-lg), print {
    font-size: 20px;
    line-height: 22px;
  }

  @media screen and (max-width: $breakpoint-md) {
    font-size: 16px;
    line-height: 18px;
  }
}

.h4 {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  letter-spacing: 0.02em;
  margin-bottom: 0;

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 16px;
    line-height: 20px;
  }
}

.h5 {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: $breakpoint-lg), print {
    margin-bottom: 8px;
  }
}

.h6 {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 2px;
}

.subhead {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;

  @media screen and (max-width: $breakpoint-lg), print {
    line-height: 20px;
  }

  @media print {
    font-size: 10px;
    line-height: 15px;
  }
}

.label {
  color: $black;
  font-size: 13px;
  line-height: 14px;

  @media screen and (max-width: $breakpoint-lg), print {
    font-size: 12px;
  }
}

.big-label {
  color: $lightGray;
  font-size: 14px;
  line-height: 20px;
}

.filter {
  font-size: 13px;
  line-height: 17px;
  margin: 0;

  @media screen and (max-width: $breakpoint-lg), print {
    font-size: 14px;
  }
}

.form-checkbox,
.form-radio {
  font-size: 16px;
  line-height: 18px;
  margin: 0;
  a,
  a:link,
  a:visited {
    color: $teal;
  }

  @media screen and (max-width: $breakpoint-lg), print {
    font-size: 12px;
    line-height: 16px;
  }
}

.list-item {
  @media screen and (max-width: $breakpoint-lg), print {
    font-size: 14px;
    line-height: 20px;
  }
}

.header-label {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;

  @media screen and (max-width: $breakpoint-lg), print {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: $white;
  }
}

.tooltip {
  color: $white;
  margin-bottom: 9px;
  font-size: 13px;
  display: block;
  line-height: 19px;
  font-weight: 400;
}

.h3-finder {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0;
}

.h4-card {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0;

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 14px;
    line-height: 20px;
  }

  @media print {
    font-size: 0.9375em;
  }
}

.reevoo-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  span {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: $black;
  }

  @media screen and (max-width: $breakpoint-sm) {
    font-size: 12px;
    line-height: 16px;
    span {
      font-size: 16px;
      line-height: 16px;
    }
  }
}

.footer-link {
  font-size: 14px;
  line-height: 18px;
  transform: scale(1);
  display: inline-block;
  transition: transform 150ms ease;
  &:link,
  &:visited {
    &:hover {
      transform: scale(1.06);
      text-decoration: underline;
    }
  }

  @media screen and (max-width: $breakpoint-lg), print {
    line-height: 20px;
  }
}

.footer-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 12px;

  @media screen and (max-width: $breakpoint-lg), print {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 18px;
  }
  &:link,
  &:visited {
    &:hover {
      text-decoration: underline;
    }
  }
}

.error {
  font-size: 12px;
  line-height: 16px;
  padding-left: 14px;
  margin-bottom: 16px;
}

.collapse-content {
  font-size: 16px;
  line-height: 32px;
  color: $black;
  margin: 0;

  @media screen and (max-width: $breakpoint-lg), print {
    font-size: 14px;
    line-height: 22px;
  }
}

.quote-card-title {
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  text-transform: uppercase;

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 12px;
    line-height: 16px;
  }

  @media print {
    font-size: 1em;
  }
}

.block-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  @media print {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 5px;
  }
}

.black {
  color: $black;
}

.white {
  color: $white;
}

.light-black {
  color: $black;
}

.dark {
  color: $black;
}

.pink {
  color: $pink;
}

.light-gray {
  color: $lightGray;
}

.teal {
  color: $teal;
  &:hover {
    color: $teal !important;
  }
}

.disabled {
  color: $disabled-value;
}

.aboutHero {
  font-size: 40px;
  font-weight: bold;
  line-height: 55px;
  margin: 0;

  @media screen and (max-width: $breakpoint-lg), print {
    font-size: 32px;
    line-height: 40px;
  }

  @media screen and (max-width: $breakpoint-md) {
    font-size: 20px;
    line-height: 25px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    font-size: 14px;
    line-height: 19px;
  }
}

.preLine {
  white-space: pre-line;
}

