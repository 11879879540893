@import "styles/variables";
@import "styles/mixins";


.button {
  font-size: 14px;
  text-transform: uppercase;
  line-height: 20px;
  text-align: center;
  font-weight: bold;
  padding: 10px 38px;
  outline: none;
  position: relative;
  cursor: pointer;

  @include transition-default(background-color color visibility opacity border);
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    pointer-events: none;
  }
  &.loading {
    color: transparent;
    .spin {
      opacity: 1;
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 14px;
  }
}

.buttonIcon {
  color: $teal;
  font-size: 16px;
  margin: 0 5px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
}

.primary {
  background-color: $teal;
  color: $white;
  border: 1px solid $teal;
  padding-top: 9px;
  padding-bottom: 9px;
  &:hover,
  .buttonIcon {
    color: $white;
  }
  &.disabled {
    background-color: $disabled;
    border-color: transparent;
    cursor: not-allowed;
  }
}

.primary-outlined {
  background-color: $white;
  color: $teal;
  border: 1px solid $black;
  padding-top: 9px;
  padding-bottom: 9px;
  &:hover {
    border-color: $teal;
    color: $teal;
  }
  .spin {
    color: $teal;
  }
  &.disabled {
    background-color: $disabled;
    border-color: transparent;
    cursor: not-allowed;
    color: $white;
  }
}

.icon {
  background-color: transparent;
  border: none;
  padding: 0;
}

.link {
  background-color: transparent;
  border: none;
  padding: 0;
  text-decoration: underline;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-transform: initial;
  color: $teal;
  &:hover {
    color: $pink;
  }

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
  }
}

.secondary {
  background-color: $pink;
  color: $white;
  border: 1px solid $pink;
  padding-top: 9px;
  text-align: center;
  padding-bottom: 9px;
  &:hover,
  .buttonIcon {
    color: $white;
  }
}

.content {
  align-items: center;
  visibility: visible;
  display: inline-flex;
  &.hidden {
    visibility: hidden;
  }
}

.spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $white;
  opacity: 0;
}

.fullWidth {
  width: 100%;
}

.iconWrapper {
  position: relative;
  margin: 0 8px;
}

.iconBadge {
  font-size: 7px;
  color: $white;
  display: flex;
  height: 12px;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  border-radius: 50%;
  background-color: $pink;
  position: absolute;
  top: -3px;
  right: -3px;
}
