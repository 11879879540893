@import "styles/variables";


.frame {
  max-height: 2800px !important;
  height: 697px;
  padding-bottom: 58px;

  @media screen and (max-width: 665px) {
    min-height: 1600px;
    padding-bottom: 62px;
  }
  @media screen and (max-width: $breakpoint-sm) {
    min-height: 1450px;
    padding-bottom: 76px;
  }
}
