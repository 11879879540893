@import "styles/variables";


.wrapper {
  display: flex;
  flex-direction: row;
  padding-top: 9px;
}

.radioItem {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  :global {
    .ant-radio-inner {
      width: 20px;
      height: 20px;
      margin-right: 2px;
      border: 1px solid $black;
      &::after {
        background-color: $teal;
        transform: scale(1) translate(2px, 2px);

        @media screen and (max-width: $breakpoint-lg) {
          transform: scale(1) translate(1px, 1px);
        }
      }

      @media screen and (max-width: $breakpoint-lg) {
        width: 18px;
        height: 18px;
      }
    }
    .ant-radio-checked::after,
    .ant-radio-checked .ant-radio-inner,
    .ant-radio:hover .ant-radio-inner {
      border-color: $teal;
    }
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: $teal;
      box-shadow: 0 0 0 3px rgba($teal, 0.08);
    }
  }
}
