@import "styles/variables";


.blockWrapper {
  margin: 48px 0;
  max-width: 100%;
  overflow: hidden;

  @media screen and (max-width: $breakpoint-lg) {
    margin: 16px 0;
  }
  @media print {
    margin: 24px 0;
  }
}
