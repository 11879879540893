@import "styles/variables";


.leisurePanel {
  &,
  &:hover {
    border: none;
    border-top: 1px solid transparent;
    margin: 0;
  }
  &:nth-child(odd) {
    background-color: $gray-bg;
  }
  &:global(.ant-collapse-item-active) {
    border-top: 1px solid $teal;
  }
}

.leisureCollapse {
  background-color: transparent;
  border-bottom: 1px solid $gray-bg;
  &:global(.ant-collapse-borderless) > div:global(.ant-collapse-item) {
    border-bottom: none;
  }
  &:not(:last-child) {
    margin-bottom: 48px;
  }
}

.leisureHeader {
  color: $black;
  font-size: 16px;
  line-height: 24px;

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 14px;
    line-height: 24px;
  }
  b {
    margin-right: 12px;
    color: $black;

    @media screen and (max-width: $breakpoint-md) {
      display: block;
    }
  }
}
