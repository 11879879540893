@import "styles/variables";


:global {
  .ant-checkbox-input {
    &:active,
    &:focus {
      & ~ span {
        outline: 1px solid $teal;
        outline-offset: 1px;
      }
    }
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: $teal;
    border-color: $teal !important;
    &::after {
      background-color: $white;
      height: 2px;
    }
  }
  .ant-checkbox-wrapper {
    display: inline-flex;
    align-items: flex-start;
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: $teal;
        border-color: $teal !important;
        &::after {
          transform: rotate(45deg) scale(1.1) translate(-35%, -60%);
        }
      }
    }
    .ant-checkbox-disabled {
      & + span {
        color: $black;
        font-size: 14px;
        line-height: 21px;
      }
      .ant-checkbox-inner {
        border-color: $checkbox-disabled !important;
        &::after {
          border-color: $white;
        }
      }
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          border-color: $checkbox-disabled !important;
          background-color: $checkbox-disabled;
        }
      }
    }
    .ant-checkbox {
      position: relative;
      & + span {
        color: $black;
      }
      .ant-checkbox-inner {
        border-radius: 0;
        border-color: $gray-600;
        width: 20px;
        height: 20px;
        &::after {
          border-color: $white;
        }
        &:hover {
          background-color: rgba($teal, 0.1);
        }
      }
    }
    &:hover {
      .ant-checkbox {
        &::after {
          visibility: hidden;
        }
      }
    }
  }
}

.wrapper {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
}

.large {
  @media screen and (max-width: $breakpoint-lg) {
    :global {
      .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
        width: 18px;
        height: 18px;
      }
      .ant-checkbox-inner::after {
        left: 16%;
      }
    }
  }
}

.info {
  margin-left: 5px;
}
