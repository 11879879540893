@import "styles/variables";


.default {
  height: 1px;
  background-color: $gray-550;
  color-adjust: exact !important;
}

.page {
  composes: default;
  margin: 48px 0;
}

.card {
  composes: default;
  margin: 20px 0;
}
