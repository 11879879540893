@import "styles/variables";
@import "styles/mixins";


.parent {
  overflow: hidden;
  position: relative;

  @include transition-default(height);
  &.showMore {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 17px;
      box-shadow: inset 0 -25px 25px $white;
    }
  }
}

.showMoreButton {
  border: none;
  box-shadow: none;
  color: $pink;
  text-transform: uppercase;
  font-weight: 700;
  background: transparent;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;
  cursor: pointer;

  @media screen and (max-width: $breakpoint-lg) {
    line-height: 20px;
    font-size: 16px;
    margin-top: 10px;
  }
}

.sortDown {
  transform: rotate(180deg);
  margin: -5px 0 5px;
}
