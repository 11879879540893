.container {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
