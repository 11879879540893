@import "styles/variables";
@import "styles/mixins";

.subHead {
  font-size: 14px;
  line-height: 16px;
  margin-top: 4px;
  font-weight: 600;

  &.withLink,
  &.withLink:link,
  &.withLink:visited {
    color: $teal;

    &:hover {
      color: $black;
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 16px;
  }
}

.headerTitle {
  display: flex;
  align-items: center;
  button {
    position: relative;
    z-index: 2;

    @media screen and (max-width: $breakpoint-lg) {
      top: 0;
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 14px;
  }
}

.formWrapper {
  background-color: $white;
  padding: 32px 20px 24px;
  border: 1px solid $gray-border;
  position: relative;
  margin: 10px 0;
  &:last-of-type {
    margin-bottom: 0;
  }
  & + div:not(.formWrapper) {
    margin-top: 48px;
  }

  @media screen and (max-width: $breakpoint-lg) {
    padding: 24px 12px 16px;
  }
}

.quoteButton {
  @media screen and (max-width: $breakpoint-lg) {
    width: 100%;
  }
}

.formRow {
  @include transition-default(opacity);
  &.loading {
    opacity: 0.4;
    pointer-events: none;
  }
}

.text {
  margin-bottom: 24px;
  svg {
    margin-left: 8px;
  }
}

.rightBlock {
  text-align: right;

  @media screen and (max-width: $breakpoint-lg) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 15px;
  }
  button {
    min-width: 150px;
  }
}

.priceText {
  margin-top: 20px;
  font-size: 13px;
  color: $black;
  line-height: 20px;
  font-weight: 700;

  @media screen and (max-width: $breakpoint-lg) {
    margin: 0 34px 0 0;
    font-size: 12px;
    line-height: 12px;
  }
  span {
    font-size: 18px;
    line-height: 24px;

    @media screen and (max-width: $breakpoint-lg) {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.selectWrapper {
  max-width: 250px;

  @media screen and (max-width: $breakpoint-lg) {
    max-width: 100%;
  }
}

.formBlock {
  background-color: $white;
  padding-bottom: 24px;
  position: relative;
}

.removeButton {
  svg {
    color: $red;
  }
  @media screen and (max-width: $breakpoint-lg) {
    font-size: 20px;
  }
}
