@import "styles/variables";
@import "styles/mixins";

.input {
  width: 100%;
  padding: 10px 12px;
  font-size: 13px;
  line-height: 20px;
  z-index: 7;
  background-color: $white;
  box-shadow: none;
  border: none;
  outline: none;
  display: block;
  &:focus,
  &:hover,
  &:active {
    box-shadow: none;
    border: none;
    outline: none;
  }
  &.xlarge {
    font-size: 16px;
    padding: 14px 12px;
  }
  &.disabled {
    color: $disabled-value;
  }

  @media screen and (max-width: $breakpoint-lg) {
    padding: 8px 12px;
    font-size: 14px;
  }
}

.textAreaWrapper {
  display: inline-block;
  width: 100%;
  textarea.input {
    padding-top: 0;
    margin-top: 10px;

    @media screen and (max-width: $breakpoint-lg) {
      margin-top: 8px;
    }
  }
  &.xlarge {
    textarea.input {
      margin-top: 14px;
    }
  }
}
