@import "styles/variables";

.buttonCart {
  width: 100%;
  margin: 6px 0 10px;

  @media screen and (max-width: $breakpoint-lg) {
    width: 100%;
    max-width: 290px;
    margin: 0 5px;
    padding: 9px 10px;
    &:first-of-type {
      margin-bottom: 12px;
    }
  }
}

.cartFixedBlock {
  position: sticky;
  top: 75px;

  @media (max-width: $breakpoint-lg) {
    position: initial;

    /* rewrite inline styles */
    top: 0 !important;
    height: 100%;
  }
}

.cartFooter {
  @media screen and (max-width: $breakpoint-lg) {
    position: sticky;
    bottom: 0;
    padding: 10px 15px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $gray-border;
    background-color: $white;
  }
}

.cartHeader {
  display: none;

  @media screen and (max-width: $breakpoint-lg) {
    position: sticky;
    bottom: 100%;
    border-bottom: 1px solid $gray-450;
    padding: 20px 15px 19px;
    display: flex;
    justify-content: space-between;
    min-height: 60px;
    & > button {
      margin-left: -10px;
    }
  }
}


.cartBody {
  background-color: transparent;
  max-height: calc(100vh - 185px);
  overflow: auto;

  @media screen and (max-width: $breakpoint-lg) {
    max-height: auto;
    flex-grow: 1;
    overflow: auto;
    background-color: $white;
    height: calc(100% - 175px);
    & > div {
      border: 1px solid transparent;
      &:hover {
        border-bottom: 1px solid $gray-border;
        border-top: 1px solid transparent;
      }
      &:not(:first-of-type) {
        border-bottom: 1px solid $gray-border;
      }
    }
  }
}

.rightBlock {
  width: 24px;
}

.arrowButton {
  color: $black;
}

.button {
  width: 100%;
  padding: 10px;
  margin: 0 auto;
  display: block;

  @media screen and (max-width: $breakpoint-lg) {
    max-width: 290px;
  }
}

.footer {
  @media screen and (max-width: $breakpoint-lg) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.modalWrapper {
  :global {
    .ant-modal-body {
      background-color: $gray-light-bg;
      max-height: calc(100vh - 200px);
      overflow: auto;

      @media screen and (max-height: 500px) {
        min-height: calc(100% - 55px);
      }
    }
    .ant-modal-header {
      display: none;
      .ant-modal-title {
        color: transparent;
      }

      @media screen and (max-width: $breakpoint-lg) {
        display: block;
      }
    }
    .ant-modal-close {
      @media screen and (max-width: $breakpoint-lg) {
        right: auto;
        left: 0;
      }
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    height: 100%;
    top: 0;
    margin: 0;
    max-width: 100%;

    /* rewrite inline width of antd modal */
    width: 100% !important;
    padding: 0;
    :global {
      .ant-modal-content {
        height: 100%;
      }
      .ant-modal-body {
        height: calc(100% - 55px);
        max-height: 100%;
      }
    }
  }
}

.anotherOptionWrapper {
  @extend .modalWrapper;
  :global {
    .ant-modal-body {
      overflow: visible;
    }
  }
}

.mockOption {
  padding: 20px 40px 20px 20px;
  background-color: $white;
  margin-bottom: 10px;
  border: 1px solid $gray-border;
}

.cartPanel {
  padding-bottom: 0;
  transition: padding-bottom 0.2s;
  &:global(:not(.ant-collapse-item-active)) {
    padding-bottom: 15px;
  }
  :global {
    .ant-collapse-header {
      /* rewrite default panel padding from antd */
      padding-left: 16px !important;
      padding-right: 16px !important;
      padding-bottom: 5px !important;
    }
  }
}
