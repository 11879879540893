@import "styles/variables";


.textRow {
  margin: 4px 0;
  display: flex;
  span:first-child {
    display: inline-block;
    min-width: 140px;

    @media screen and (max-width: $breakpoint-lg) {
      min-width: 110px;
    }

    @media print {
      min-width: 80px;
    }
  }
}
