@import "styles/variables";
@import "styles/mixins";

.cardSlider {
  & > button {
    z-index: 1;
    opacity: 0;

    @include transition-default(opacity);
    &::before {
      @media screen and (max-width: $breakpoint-lg) {
        font-size: 30px;
      }

      @media screen and (max-width: $breakpoint-sm) {
        font-size: 20px;
      }
    }
    &:first-of-type {
      left: 15px;
    }
    &:last-of-type {
      right: 15px;
    }
  }
  &:hover {
    & > button {
      opacity: 1;
    }
  }
  
  :global(.slick-active) {
     z-index: 2;
  }
}

.cardImgBlock {
  height: 292px;
  margin-bottom: -7px;
  width: 100%;
  object-fit: cover;
  @media screen and (max-width: $breakpoint-lg) {
    height: 212px;
  }

  @media screen and (max-width: $breakpoint-md) {
    height: 172px;
  }
}

.imgPlaceholder {
  width: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cardImg {
  min-height: 291px;

  @media screen and (max-width: $breakpoint-lg) {
    min-height: 212px;
  }

  @media screen and (max-width: $breakpoint-md) {
    min-height: 172px;
  }
}

.slideWrapper {
  position: relative;

  @media print {
    display: none;
  }
}

.printImage {
  display: none;
  position: relative;

  @media print {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.sliderOrdering {
  position: absolute;
  bottom: 12px;
  right: 12px;
  z-index: 2;
  background-color: $white;
  color: $black;
  padding: 6px;
  line-height: 10px;
  font-size: 12px;
  font-weight: 700;

  @media print {
    display: none;
  }
}
