@import "styles/variables";
@import "styles/mixins";

.cta {
  font-weight: 600;
  color: $white;
  &:hover {
    color: $white;
    &.underline {
      text-decoration: underline;
    }
    .icon {
      transform: translateX(10px);
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 16px;
    line-height: 20px;
  }
}

.icon {
  margin-left: 10px;

  @include transition-default(transform);
}
