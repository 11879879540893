@import "styles/variables";
@import "styles/mixins";

.select {
  font-size: 13px;
  line-height: 20px;

  @media screen and (max-width: $breakpoint-lg) {
    .valueContainer {
      font-size: 16px;
    }
    .option {
      font-size: 16px;
      line-height: 20px;
    }
  }
  & > div:first-of-type {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    background-color: $white;
    box-shadow: none;
    border-radius: 0;
    min-height: 32px;
    cursor: pointer;
    overflow: hidden;
    border: none !important;
    outline: none !important;
  }
  &.medium {
    & > div:first-of-type {
      min-height: 32px;
    }
  }
  &.large {
    @media screen and (max-width: $breakpoint-lg) {
      font-size: 12px;
    }
    & > div:first-of-type {
      min-height: 40px;

      @media screen and (max-width: $breakpoint-lg) {
        min-height: 36px;
      }
    }
  }
  &.xlarge {
    @media screen and (max-width: $breakpoint-lg) {
      font-size: 16px;
    }
    & > div:first-of-type {
      min-height: 48px;
    }
    .valueContainer {
      font-size: 16px;
    }
    .option {
      font-size: 16px;
    }
  }
  .menuList,
  [class$="menu"] {
    background-color: $white;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.16);
    max-width: 316px;
    width: auto;
    min-width: 100%;
    z-index: 1000;
    div {
      cursor: pointer;
    }
  }
  &.disabled {
    pointer-events: none;
    & > div:first-of-type * {
      color: $disabled-value;
    }
    .indicatorWrapper {
      display: none;
    }
  }
}

div.option {
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;
  color: $black;
  line-height: 20px;
  background-color: $white;
  cursor: pointer;
  &:hover:not(.disabledOption),
  &.focused:not(.disabledOption) {
    background-color: rgba($teal, 0.2);
  }
  &.disabledOption {
    cursor: not-allowed;
    color: $disabled-value;
    pointer-events: none;
  }
  :global {
    .ant-checkbox-disabled {
      & + span {
        font-size: 16px;
        color: $disabled-value;
      }
    }
  }
}

.removeWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  border: none;
}

.multiValue {
  cursor: pointer;
  &:nth-child(1) {
    padding: 2px 2px 1px 4px;
  }
  &:hover {
    .removeWrapper {
      background-color: rgba($teal, 0.2);
    }
  }
}

.remove {
  width: 12px;
  height: 12px;
  display: inline-block;

  @include background-prop();
}

.locationSelect {
  margin-bottom: 18px;
}

.layout {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.indicator {
  transform: scale(1.2);
  color: $teal;
  margin: -5px 15px 0;
}

.clearIndicator {
  & > div {
    padding-right: 0;
    margin-right: -5px;
    position: relative;
    z-index: 1001;
  }
}

.valueContainer {
  font-size: 13px;
  padding-left: 9px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 14px;
  }
  & > div:first-child {
    color: $black;
    padding-right: 25px;
    margin-left: 4px;
  }
  & > span {
    display: inline-block;
  }
}

.largeFont {
  .valueContainer {
    font-size: 16px;
  }
}

.smallFont {
  .valueContainer {
    font-size: 12px;
    & > div:first-child {
      padding-right: 55px;
    }
  }
}

div.multiOption {
  display: flex;
  align-items: center;
  padding: 5px 12px;
}

.checkboxLabel {
  font-size: 16px;
}

.rtl {
  direction: rtl;
  opacity: 0;
}

