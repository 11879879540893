@import "styles/variables";

.container {
  margin: 0 auto;
  width: 100%;
}

.lg {
  composes: container;
  max-width: 1180px;
}

.padding {
  max-width: 1210px;
  padding-left: 15px;
  padding-right: 15px;
}

.xl {
  composes: container;
  max-width: 1372px;
  &.padding {
    max-width: 1440px;
    padding-left: 34px;
    padding-right: 34px;

    @media screen and (max-width: $breakpoint-lg) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media print {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.header {
  composes: container;
  max-width: 1328px;
  &.padding {
    max-width: 1358px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
