@import "styles/variables";

.subHead {
  font-size: 12px;
  line-height: 16px;
  color: $pink;
  font-weight: 400;
}

.textRow {
  margin: 0 0 0.25em;
  display: flex;
  &.margin {
    margin: 1em 0;
  }
  & > span:first-child {
    width: 60%;
  }
  & > span:last-child {
    width: 40%;
    display: inline-flex;
    justify-content: flex-end;
  }
  &.savings {
    flex-direction: row-reverse;
    & > span {
      width: 100%;
    }
  }
}

.cartItemWrapper {
  &:not(:first-of-type) {
    margin-top: 2em;
  }
}

.divider {
  margin: 1em 0;
  min-height: 1px;

  @media screen and (max-width: $breakpoint-lg) {
    margin: 12px 0;
  }
  & + .cartItemWrapper {
    margin-top: 0;
  }
}

.title {
  margin-bottom: 0.125em;
}

.promotionTitle {
  margin-bottom: 12px;
}

.colorPink {
  color: $pink;
}

.schoolDesktopName {
  & + .cartItemWrapper {
    margin-top: 8px;
  }

  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}

.textWrapper {
  margin-top: 0.75em;
  &.noMargin {
    margin-top: 0;
  }

  @media screen and (max-width: $breakpoint-lg) {
    margin-top: 8px;
  }
}

.spacing {
  margin-bottom: 20px;
}

.noMargin {
  margin-top: 0;
}

.cartTooltip {
  margin-left: 5px;
}

.voucherField {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 8px;
  & > div:first-child {
    position: relative;
    flex: 1;
    .closeIcon {
      display: none;
      position: absolute;
      top: 8px;
      right: 0px;
      font-size: 20px;
      svg {
        color: $black;
      }
      &.display {
        display: inline-block;
      }
    }
  }
  & > .btn {
    flex: 0;
    height: 40px;
    @media screen and (max-width: $breakpoint-lg) {
      height: 36px;
      padding: 7px 32px;
    }
  }
  @media screen and (min-width: $min-breakpoint-lg) and (max-width: $breakpoint-xxl) {
    gap: 4px;
    & > .btn {
      padding-left: 4px;
      padding-right: 4px;
      font-size: 13px;
    }
  }
}

.marginBottom {
  margin-bottom: 1em;
}
