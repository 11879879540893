@import "styles/variables";

.checkWrapper {
  padding-top: 10px;
}

.wrapper {
  padding-top: 40px;

  @media screen and (max-width: $breakpoint-lg) {
    padding-top: 15px;
  }
}

.formTitle {
  margin-bottom: 0;
  &.withMargin {
    margin-bottom: 40px;
  }
  &.compactTitle {
    margin-bottom: 8px;
    padding-top: 50px;
  }
}

.formSubtitle {
  margin-bottom: 40px;

  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 10px;
  }
}

.subsectionField {
  margin: 5px 0 15px;
}

.nextAlignRight {
  @media screen and (max-width: $breakpoint-lg) {
    flex: auto;
  }
  & + .fieldCol {
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: $breakpoint-lg) {
      flex: auto;
    }
  }
}

.info {
  margin-left: 5px;
}

.spacingField {
  padding: 5px 0;
}

.columnAlign {
  flex-direction: column;
  align-items: stretch;
}
