@import "styles/variables";
@import "styles/mixins";

.container {
  height: 100%;
  position: relative;
  background-position: center;
  background-size: cover;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @include transition-default(background-color, 0.5s);
  }
  &:hover {
    &::before {
      background-color: rgba($teal, 0.85);
      z-index: 1;
    }
    .cta {
      color: $white;
    }
    .heading {
      position: relative;
      transform: translateY(50px);

      @media screen and (max-width: $breakpoint-md) {
        transform: translateY(40px);
      }

      @media screen and (max-width: $breakpoint-sm) {
        transform: translateY(30px);
      }

      @include transition-default(transform, 0.5s);
    }
    .text {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.gridImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
}

.heading {
  position: absolute;
  bottom: 40px;
  left: 0;
  margin-bottom: 20px;
  max-width: 100%;
}

.title {
  margin-bottom: 0;
  white-space: pre-line;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text {
  margin-top: 50px;
  opacity: 0;
  transform: translateY(-30px);

  @media screen and (max-width: $breakpoint-md) {
    margin-top: 40px;
    transform: translateY(-20px);
  }

  @media screen and (max-width: $breakpoint-sm) {
    margin-top: 30px;
    transform: translateY(-15px);
  }

  @include transition-default(opacity transform, 0.5s);
}
